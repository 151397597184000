import tippy, { Props as TippyProps, Instance as TippyInstance } from 'tippy.js'
import { Directive, DirectiveHook } from 'vue'
import { defaultTooltipOptions } from '@/modules/base/config/tooltipConfig'

interface TooltipArgumentsComplex {
  content: string
  options?: Partial<TippyProps>
  /**
   * Will add `tabindex` of `0` and `outline-white-var` styles to this element
   * @default true
   */
  includeElementDefaults?: boolean
}

export type TooltipArguments =
  | TooltipArgumentsComplex
  | string
  | undefined
  | null

/**
 * Allow for use of string or object as the directive's value
 */
export const unifyArgumentsType = (
  tooltipArguments: NonNullable<TooltipArguments>
): TooltipArgumentsComplex => {
  return typeof tooltipArguments === 'string'
    ? { content: tooltipArguments }
    : tooltipArguments
}

const tippyInstances = new WeakMap<HTMLElement, TippyInstance>()

const destroyTippy = (element: HTMLElement) => {
  tippyInstances.get(element)?.destroy()
  tippyInstances.delete(element)
}

const enableTippy: DirectiveHook<HTMLElement, unknown, TooltipArguments> = (
  element,
  binding
) => {
  destroyTippy(element)
  if (binding.value == null) {
    return
  }

  const {
    content,
    includeElementDefaults = true,
    options,
  } = unifyArgumentsType(binding.value)

  if (includeElementDefaults) {
    element.setAttribute('tabindex', '0')
    element.classList.add(
      'focus:outline-none',
      'focus-visible:outline-white-var'
    )
  }

  const instance = tippy(element, {
    ...defaultTooltipOptions,
    content,
    ...options,
  })

  tippyInstances.set(element, instance)
}

export const createTooltipDirective = (): Directive<
  HTMLElement,
  TooltipArguments
> => ({
  mounted: enableTippy,
  beforeUpdate: enableTippy,
  beforeUnmount: destroyTippy,
})
